import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: true
        }
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/perfil',
        name: 'perfil',
        component: () => import('./views/Perfil.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/produtos',
        name: 'produtos',
        component: () => import('./views/produtos/Produtos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pedidos',
        name: 'pedidos',
        component: () => import('./views/Pedidos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/pedidos-pendentes',
        name: 'pedidos-pendentes',
        component: () => import('./views/PedidosPendentes.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/distribuidores',
        name: 'distribuidores',
        component: () => import('./views/Distribuidores.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/carrinhos',
        name: 'carrinhos',
        component: () => import('./views/Carrinhos.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/impressao/:id',
        name: 'impressao',
        component: () => import('./views/impressao/Pedido.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: true
        }
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
