export default class {

    static emptyStr(val) {
      if(typeof val == "undefined" || val == null)
        return "";
      return val;
    }
      
    static formatPhone(phone) {
      phone = phone.replace(/[^0-9]/g, "");
      return '(' + phone.substr(0,2) + ') ' + phone.substr(2,phone.length-6) + '-' + phone.substr(-4);
    }

    static formatCurrency(val) {
        if(typeof val == "string")
            val = parseFloat(val);
        else if(typeof val != "number")
            return "";

        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
    }

    static formatDateToBr(date) {
        if(typeof date != "string" || date == "")
            return "";
        return date.split("-").reverse().join("/");
    }

    static formatDateToMysql(date) {
        if(typeof date != "string" || date == "")
            return "";
        return date.split("/").reverse().join("-");
    }

    static formatDateHourToBr(date) {
      if(typeof date != "string" || date == "")
          return "";
      var dateHour =  date.split(" ");
      return dateHour[0].split("-").reverse().join("/")  + " " + dateHour[1];
    }

    static formatDateHourToMysql(date) {
      if(typeof date != "string" || date == "")
          return "";
      var dateHour =  date.split(" ");
      return dateHour[0].split("/").reverse().join("-") + " " + dateHour[1];
    }

    static getStdObject(obj) {
      if(typeof obj == "undefined" || obj == null)
        return null;
      return JSON.parse(JSON.stringify(obj));
    }

    static isCPF(strCPF) {
        strCPF = strCPF.replace(/[^0-9]/g, "");
  
        var i;
        var Soma;
        var Resto;
        Soma = 0;
  
        if (strCPF == "00000000000") return false;
  
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
  
        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;
  
        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
  
        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  
        return true;
    }

    static isCNPJ(cnpj) {
        var i;
  
        cnpj = cnpj.replace(/[^\d]+/g, "");
  
        if (cnpj == "") return false;
  
        if (cnpj.length != 14) return false;
  
        // Elimina CNPJs invalidos conhecidos
        if (
          cnpj == "00000000000000" ||
          cnpj == "11111111111111" ||
          cnpj == "22222222222222" ||
          cnpj == "33333333333333" ||
          cnpj == "44444444444444" ||
          cnpj == "55555555555555" ||
          cnpj == "66666666666666" ||
          cnpj == "77777777777777" ||
          cnpj == "88888888888888" ||
          cnpj == "99999999999999"
        )
          return false;
  
        // Valida DVs
        var tamanho = cnpj.length - 2;
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
  
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) pos = 9;
        }
  
        var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  
        if (resultado != digitos.charAt(0)) return false;
  
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
  
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2) pos = 9;
        }
  
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  
        if (resultado != digitos.charAt(1)) return false;
  
        return true;
    }

}